<template>
	<div>
	<ContentHeader title="Rekam Medis" />
     <div class="content-header">
        <div class="container-fluid">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title"><div>Rekam Medis</div></h3>

              <div class="card-tools">
                <button type="button" class="btn btn-tool" data-card-widget="collapse" title="Collapse">
                  <i class="fas fa-minus"></i>
                </button>
              </div>
            </div>
            <div class="card-body" style="display: block;">
              <div class="row" v-if="toogleCabang">
                <div class="col-lg-4">
                  <label>Pilih Cabang</label>
                  <multiselect 
                    v-model="cabang_select_value"
                    deselect-label="Can't remove this value"
                    track-by="nama"
                    label="nama"
                    placeholder=""
                    :options="cabang"
                    :loading="loading_cabang"
                    :allow-empty="false"
                    @update:model-value="getDataResult"
                  >
                  </multiselect>
                </div>
              </div>
              <div class="row align-items-center">
                <div class="col-lg-2">
                  <span>Jumlah Baris</span>
                  <select class="custom-select form-control" v-model="page" @change="getDataResult">
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="25">25</option>
                    <option value="30">30</option>
                    <option value="40">40</option>
                    <option value="45">45</option>
                    <option value="50">50</option>
                  </select>
                </div>
                <div class="col-lg-6"></div>
                <div class="col-lg-4">
                  <span>Cari Data</span>
                  <input type="text" class="form-control" v-model="kata_kunci" @keyup="getDataResult">
                </div>
              </div>
              <table class="table table-bordered table-hover mt-3">
                <thead>
                  <tr>
                    <th scope="col" class="text-sm">REKAM MEDIS</th>
                    <th scope="col" class="text-sm">NAMA</th>
                    <th scope="col" class="text-sm">NO. ID</th>
                    <th scope="col" class="text-sm">NO. ASURANSI</th>
                    <th scope="col" class="text-sm">ALAMAT</th>
                    <th scope="col" class="text-sm">TELEPON</th>
                    <th scope="col" class="text-sm">GENDER</th>
                    <th scope="col" class="text-sm">AGAMA</th>
                    <th scope="col" class="text-sm">PEKERJAAN</th>
                    <th scope="col" class="text-sm">ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="row in data_result" :key="row.id">
                    <td class="text-sm" scope="row">{{ row.nomor_rekam_medis }}</td>
                    <td class="text-sm">{{ row.nama }}</td>
                    <td class="text-sm">{{ row.nomor_identitas }}</td>
                    <td class="text-sm"><b>{{ row.asuransi }}</b>-{{ row.nomor_asuransi }}</td>
                    <td class="text-sm">{{ row.alamat_lengkap }}</td>
                    <td class="text-sm">{{ row.nomor_telepon }}</td>
                    <td class="text-sm">{{ row.jenis_kelamin }}</td>
                    <td class="text-sm">{{ row.agama }}</td>
                    <td class="text-sm">{{ row.pekerjaan }}</td>
                    <td class="text-sm">
                    <div class="btn-group">
                      <router-link :to="`/rekam-medis-detail/${row.id}`" class="btn btn-sm btn-info text-sm">Detail</router-link>
                    </div>
                  </td>
                  </tr>
                </tbody>
              </table>
              <p class="text-center mt-2" v-if="memuat_data">Memuat Data...</p>
              <p class="text-center mt-2" v-if="cek_data">Data Masih Kosong</p>
            </div>
            <!-- /.card-body -->
            <div class="card-footer">
              <nav aria-label="...">
                <ul class="pagination">
                  <li class="page-item" :class="{ disabled: pagination.current_page == pagination.from}" @click="paginateData(pagination.prev_page_url)">
                    <a class="page-link" href="#" tabindex="-1" aria-disabled="true">Previous</a>
                  </li>
                  <li class="page-item" :class="{ active: pagination.current_page == row.label }" @click="paginateData(row.url)" v-for="(row, index) in links" :key="index"><a class="page-link" href="#">{{ row.label }}</a></li>
                  <li class="page-item" :class="{ disabled: pagination.current_page == pagination.last_page}" @click="paginateData(pagination.next_page_url)">
                    <a class="page-link" href="#">Next</a>
                  </li>
                </ul>
              </nav>
            </div>
            <!-- /.card-footer-->
          </div>
        </div>
      </div>
    </div>
</template>

<script setup>
import ContentHeader from '@/components/ContentHeader'
import Multiselect from '@suadelabs/vue3-multiselect'

import { ref, computed, onMounted } from 'vue'
import store from '@/store'
import axios from 'axios'

const user = computed(() => store.getters['auth/user'])
const data_result = ref([])
const cek_data = ref(false)
const page = ref(10)
const links = ref([])
const pagination = ref([])
const memuat_data = ref(false)
const kata_kunci = ref('')

const getDataResult = async () => {
  data_result.value = []
  memuat_data.value = true
  cek_data.value = false

  let parameter = ''
  if (kata_kunci.value != '') {
    parameter = kata_kunci.value
  } else {
    parameter = 'emptyParameter'
  }

  let cabang_id = ''

  if (cabang_select_value.value == '') {
    cabang_id = user.value.cabang_id
  } else {
    cabang_id = cabang_select_value.value.id
  }

  let { data } = await axios.get(`api/pasien/getDataHasRekamMedis/${cabang_id}/${page.value}/${parameter}`)

  if (data != 'kosong') {
    memuat_data.value = false
    cek_data.value = false
    data_result.value = data.data
    links.value = data.links
    links.value.splice(0, 1)
    links.value.splice(links.value.length-1, 1)
    pagination.value = data
  } else {
    cek_data.value = true
    memuat_data.value = false
    data_result.value = []
  }
}

const paginateData = async (url) => {

    if (url != null) {
      data_result.value = []
      memuat_data.value = true

      let link_url = new URL(url)
      link_url = `${link_url.pathname}${link_url.search}`
      
      let { data } = await axios.get(`${link_url}`)

      
      if (data != 'kosong') {
        memuat_data.value = false
        data_result.value = data.data 
        links.value = data.links
        links.value.splice(0, 1)
        links.value.splice(links.value.length-1, 1)
        pagination.value = data
      }
    }
  }

const toogleCabang = ref(false)
const cabang_select_value = ref('')
const cabang = ref([])
const loading_cabang = ref(true)
const getCabang = async () => {
  let response = await axios.get(`api/cabang/getWhereId/${user.value.cabang_id}`)

  if (response.data.jenis == 'pusat') {
    toogleCabang.value = true
    let { data } = await axios.get(`api/cabang/getWhereCabang/${response.data.id}`)
    
    if (data != 'kosong') {
      cabang.value = data
      loading_cabang.value = false
    } else {
      cabang.value = []
      loading_cabang.value = false
    }
  }
}


onMounted(() => {
  getCabang()
  getDataResult()
})
</script>